.gstBillItemFontSize {
  font-size: 12px
}

body {
  background-color: rgb(238, 242, 246) !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

a:hover {
  color: inherit !important;
}

ul {
  list-style: none;
  padding: 0 !important;
}

.hidden {
  display: none !important;
}

nav {
  position: relative;
  /* background-color: #1C2536; */
  background-color: #04281d;
  color: white;
  padding: 10px;
}


.aside-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
}

aside {
  width: 230px;
  color: white;
  /* background-color: #1C2536; */
  background-color: #04281d;
  padding: 20px 15px 15px 15px;
  transition: .25s;
  position: relative;
}

.hamburgerConainer {
  position: absolute;
  right: -30px;
  top: -40px;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.side-menu-item {
  width: 100%;
  margin: 5px 0;
  cursor: pointer;
  color: #9DA4AE;
  font-weight: 500;
}

.side-menu-item:hover {
  background: rgba(255, 255, 255, 0.04);
}

.active-side-menu-item {
  background-color: rgba(255, 255, 255, 0.04);
  width: 100%;
  height: 100%;
  padding: 10px;
  color: white !important;
  display: flex;
  gap: 10px;
}

.inactive-side-menu-item {
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;

}

.side-menu-icons {
  font-size: 20px;
}

.main-content {
  padding: 10px 10px 10px 10px;
  margin-bottom: 50px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

main {
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  /* width: 100%; */
}

.menu-item-collapse {
  width: 70px;
}

.menu-item-expand {
  width: 250px;
}

.page-title {
  padding-left: 15px;
  font-size: 20px;
  font-weight: 900;
}


.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.form-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.searchbillingclient {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.searchFilterDropdown {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* max-width: 360px; */
}

.custom-dropdown-items {
  background-color: white;
  z-index: 99;
}

.test {
  background-color: blue;
  z-index: 2;
}

.test::before {
  position: absolute;
  content: "";
  background: linear-gradient(45deg, #c3ffaa33, transparent);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.dash-data-table-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.dash-data-table-row main {
  width: 45%;
  overflow: auto;
}

.dash-table {
  width: 100%;
}

.dash-table tr td,
.dash-table tr th {
  border-top: 1px dashed lightgrey;
  border-bottom: 1px dashed lightgrey;
  color: darkgrey;
  padding: 10px;
}

.dash-table tr th {
  font-weight: 500;
}

.dash-intro-container {
  background: linear-gradient(45deg, #d9f9e7, #ccf1e1);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  text-align: center;
  border-top: 1px solid black;
}

.hcgwbo {
  font-size: 14px;
  color: grey;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .dash-intro-container {
    display: none;
  }

  .menu-item-collapse {
    width: 0;
    padding: 0;
  }

  .searchbillingclient {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }

  .searchFilterDropdown {
    flex-direction: column;
    align-items: start;
  }

  .ddd {
    margin-top: 23px;
  }

  .dash-data-table-row main {
    width: 100%;
  }
}