.loginContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.login-bg {
  width: 100vw;
  height: 100vh;
  background: url("../../images/flower-decoration-freshness-beautiful-ornament.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .loginContainer {
    width: 97%;
  }
}