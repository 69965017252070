@import url('https://fonts.googleapis.com/css?family=Great Vibes');

.logoText{
    font-family: 'Great Vibes';
}
.estimate-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.invoiceTitleContainer {
    text-align: center;
    background-color: lightgrey;
    font-weight: bolder;
    padding: 10px;
    margin-top: 10px;
}

.printTable {
    width: 100%;
    /* margin: 10px; */
    height: 370px;
    border-bottom: 1px solid black;
}

.printThead {
    background-color: lightgray;
}

.inv-bank-container{
    display: grid;
    grid-template-columns: 20% 80%;
}
@media only screen and (max-width: 600px) {
    .estimate-item-container {
        flex-direction: column;
    }

}