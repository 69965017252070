.client-cards {
    background-color: white;
    padding: 10px;
    min-width: 200px;
    border-radius: 10px;
    margin: 10px;
}

.client-statement-filter {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .client-statement-filter {
        flex-direction: column;
    }
}